import React              from 'react';
import { Link, navigate } from 'gatsby';
import { Page }           from '../components/Page';
import { Header }         from '../components/organism';
import { SEO }            from '../components/atom/SEO';
import { UserManager }    from '../api/com/ewing/social/manager';
import isNode             from 'detect-node';

/**
 *
 * @return {JSX.Element}
 * @constructor
 * @author Isaac Ewing
 * @version 1.0.0 05/07/21 10:06 pm
 */
export const Dashboard = (): JSX.Element => {
     const CONSOLE_PREFIX = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } DSHB ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;

     if( UserManager.token ) {
          console.log( `${ CONSOLE_PREFIX } DASHBOARD says the user IS logged in...`, { page: Header.getActionPage(), user: UserManager.User() } );

          return (
               <Page className="page-dashboard">
                    <main>
                         <SEO title="Dashboard" />
                         <h1>Hi: { UserManager.username }</h1>
                         <p>Token: { UserManager.token }</p>
                         <Link to="/">Go back to the homepage</Link>
                    </main>
               </Page>
          );
     } else {
          console.log( `${ CONSOLE_PREFIX } DASHBOARD says the user is not logged in...`, { page: Header.getActionPage(), user: UserManager.User() } );
          const link: string = Header.getActionPage() ?? '/';

          return (
               <Page className="page-dashboard">
                    <main>
                         <SEO title="Dashboard" />
                         <h1>You need to login first</h1>
                         { !isNode ? navigate( link, null ) : <Link to={ link }>{ link }</Link> }
                    </main>
               </Page>
          );
     }
};

export default Dashboard;